import { get } from 'lodash';
import angular from 'angular';
import app from '../app';
import downloadNotification from '../modals/downloadNotification';
import { getOffset, getTagPath, createTree } from '../helpers';
import { log } from '../../vendor/exceljs/exceljs';

app.service('SearchPageService', [
  '$rootScope',
  '$filter',
  '$q',
  '$translate',
  'DisposalCodes',
  'StorageCodes',
  'SolrDocument',
  'SpinnerService',
  'Manufacturer',
  'Tag',
  'TagService',
  'Company',
  'CompanyService',
  'ExportService',
  'HazardService',
  'MAX_INT',
  'ModalService',
  'InventoryService',
  'ErrorService',
  'MessagesService',
  function SearchPageService(
    $rootScope,
    $filter,
    $q,
    $translate,
    DisposalCodes,
    StorageCodes,
    SolrDocument,
    SpinnerService,
    Manufacturer,
    Tag,
    TagService,
    Company,
    CompanyService,
    ExportService,
    HazardService,
    MAX_INT,
    ModalService,
    InventoryService,
    ErrorService,
    MessagesService
  ) {
    const vm = this;
    const simpleCatch = ErrorService.simpleCatch;
    const getCorrectCompanyId = function () {
      if ($rootScope.checkIfUserIs(['admin', 'sdsEditor'])) return null;
      if ($rootScope.companySettings.searchAllSds) return null;

      return CompanyService.getCorrectCompanyId();
    };

    vm.advancedSearch = null; // null makes it fetch facets on toggle
    vm.isAdmin = $rootScope.checkIfUserIs(['admin', 'sdsEditor']);
    vm.isIndependentSdsChild = CompanyService.isIndependentSdsChild();
    vm.companyId = getCorrectCompanyId();
    vm.realCompanyId = vm.isAdmin ? null : $rootScope.companyId;
    vm.PER_PAGE = 20;
    vm.search = {};
    vm.inventoryWithProducts = [];
    vm.inventoryWithProductsTree = [];

    vm.showArchivedToggle = false;
    vm.addArchived = get($rootScope, 'companySettings.addArchivedToSearchSds', true);
    vm.archivedTags = [];
    vm.addSensitive = $rootScope.checkIfUserIs(['admin', 'teamAdmin'])
      ? true
      : $rootScope.currentUser && $rootScope.currentUser.allowToViewSensitiveSds;
    vm.sensitiveTags = [];
    vm.advancedSearchReady = false;
    vm.lastSearchResults = null;

    vm.changeAndOrStrategy = strategyStr => {
      vm.search.andOrStrategy = strategyStr;
    };

    const getActionsWithSearchResults = function (action) {
      return (
        {
          print: function (payload) {
            return SolrDocument.print(payload).$promise;
          },
          printQRCodes: function (payload) {
            const MAX_PRINT_QR_CODES = 500;
            const totalRecords = vm.search ? (vm.search.meta ? vm.search.meta.found : 0) : 0;
            if (totalRecords >= MAX_PRINT_QR_CODES) {
              MessagesService.warning(
                'SEARCH.PRINT.LIMIT_WARN_RESTRICTION',
                {
                  limit: MAX_PRINT_QR_CODES
                },
                {
                  closeButton: true,
                  timeOut: 0,
                  extendedTimeOut: 0
                }
              );
            }
            return SolrDocument.printQRCodes(payload).$promise;
          },
          export: function (payload) {
            return SolrDocument.export(payload).$promise;
          }
        }[action] || $q.reject
      );
    };
    const getFacetFields = function (isAdmin) {
        const getPpeFacetFields = function () {
          if (
            $rootScope.checkIfUserIs('admin') ||
            ($rootScope.companySettings && $rootScope.companySettings.enablePPE)
          ) {
            return ['ppe', `ppe_${$rootScope.companyId}`];
          }
          return [];
        };
        const facetFields = isAdmin
          ? [
              'companyId',
              'language',
              'tag',
              'storageCodes',
              'disposalCodes',
              'hazardType',
              'manufacturer',
              ...getPpeFacetFields()
            ]
          : [
              'language',
              'tag',
              'storageCodes',
              'disposalCodes',
              'hazardType',
              'manufacturer',
              ...getPpeFacetFields()
            ];

        if (isInventoryEnabled()) facetFields.push('products');

        return facetFields;
      },
      getSearchFilters = function (isAdmin, companyId) {
        return isAdmin || !companyId ? [] : [{ field: 'companyId', value: companyId }];
      },
      defaults = {
        facetFields: [],
        search: {
          page: 1,
          query: '',
          docs: [],
          filters: getSearchFilters(vm.isAdmin, vm.companyId),
          meta: {
            found: null,
            query: null
          },
          limit: vm.PER_PAGE,
          offset: 0,
          facets: {
            hazardType: [],
            disposalCodes: [],
            storageCodes: [],
            language: [],
            companyId: [],
            tag: [],
            manufacturer: [],
            products: []
          },
          andOrStrategy: 'and'
        }
      };
    const collectCheckedFacets = function (facets) {
        const checked = function (itm) {
            return itm.checked;
          },
          compose = function (field) {
            return function (itm) {
              itm.field = field;
              return itm;
            };
          },
          collect = function (itms, field) {
            return itms.filter(checked).map(compose(field));
          };
        return Object.keys(facets).reduce(function (sum, facetName) {
          return sum.concat(collect(facets[facetName], facetName));
        }, defaults.search.filters);
      },
      find = function (arr, cb) {
        return arr.reduce(function (sum, itm) {
          return sum || (cb(itm) ? itm : sum);
        }, false);
      },
      has = function (arr, cb) {
        return !!find(arr, cb);
      },
      pivot = function (arr, field) {
        field = field || 'id';
        return arr.reduce(function (sum, itm) {
          sum[itm[field]] = itm;
          return sum;
        }, {});
      },
      cache = {},
      bustCache = function (name) {
        if (name) {
          cache[name] = null;
        } else {
          angular.forEach(cache, function (vl, ky) {
            cache[ky] = null;
          });
        }
      },
      getCached = function (name, getter) {
        cache[name] = cache[name] || getter();

        return cache[name].then(data => data);
      },
      getDisposalCodes = function () {
        const companyId = $rootScope.currentCompany
          ? $rootScope.currentCompany.id
          : vm.realCompanyId;

        return getCached(`disposalCodes_${companyId}`, function () {
          return DisposalCodes.find({
            filter: {
              where: {
                companySettingId: companyId
              }
            }
          }).$promise.then(function (resp) {
            return pivot(resp);
          });
        });
      },
      getStorageCodes = function () {
        const companyId = $rootScope.currentCompany
          ? $rootScope.currentCompany.id
          : vm.realCompanyId;

        return getCached(`storageCodes_${companyId}`, function () {
          return StorageCodes.find({
            filter: {
              where: {
                companySettingId: companyId
              }
            }
          }).$promise.then(function (resp) {
            return pivot(resp);
          });
        });
      },
      getEnabledCustomFields = function (companyId) {
        return getCached(`attrs_${companyId}`, function () {
          return CompanyService.CompanySettings(companyId, false).$promise.then(function (resp) {
            return resp.enabledAttrs;
          });
        });
      },
      getManufacturers = function () {
        return getCached('manufacturer', function () {
          return Manufacturer.find({ filter: { fields: ['id', 'name'] } }).$promise.then(function (
            resp
          ) {
            return pivot(resp);
          });
        });
      },
      getCompanies = function () {
        if (vm.isAdmin) {
          return getCached('companies', function () {
            return $rootScope.checkIfUserIs('sdsEditor')
              ? Promise.resolve(pivot($rootScope.currentUser.allowedCompanies))
              : Company.find().$promise.then(function (resp) {
                  return pivot(resp);
                });
          });
        } else {
          const companyId = $rootScope.currentCompany
            ? $rootScope.currentCompany.id
            : vm.realCompanyId;

          return getCached(`companies_${companyId}`, function () {
            return Company.find({
              filter: {
                where: {
                  id: companyId
                }
              }
            }).$promise.then(function (resp) {
              return pivot(resp);
            });
          });
        }
      },
      getTags = function () {
        const companyId = $rootScope.currentCompany
          ? $rootScope.currentCompany.id
          : vm.realCompanyId;

        return getCached(`tag_${companyId}`, function () {
          return $q
            .all({
              tags: Tag.find({
                filter: {
                  where: { or: [{ companyId }, { companyId: '' }] }
                }
              }).$promise,
              companies: getCompanies()
            })
            .then(function (data) {
              return pivot(
                data.tags.map(function (tag) {
                  if ($rootScope.companySettings.groupTagsByParent) {
                    const rootTag = getMainParentTag(tag, data.tags);
                    if (rootTag !== tag) {
                      tag.fullPathTitle = [rootTag.title, ...rootTag.pathToChild, tag.title].join(
                        ' > '
                      );
                    }
                  }

                  tag.companyName = (data.companies[tag.companyId] || {}).name;
                  tag.isParent = data.tags.some(function (possibleChild) {
                    return possibleChild.tagId === tag.id;
                  });
                  tag.tags = [];

                  return tag;
                })
              );
            });
        });
      },
      createTermsFilter = function (prop = '', items = []) {
        return `{!terms f=${prop}}${items}`;
      },
      getInventoryWithProducts = function (realCompanyId) {
        return getCached(`inventoryWithProducts_${realCompanyId}`, function () {
          return InventoryService.getWithProductIds(realCompanyId).then(invs => {
            let invsMap = {};
            let invsPath = {};
            invs.forEach(el => (invsMap[el.id] = el));
            const definePath = function (inv) {
              if (
                !invsPath[inv.id] &&
                inv.groupId &&
                invsMap[inv.groupId] &&
                inv.groupId !== inv.id
              ) {
                invsPath[inv.id] = [...definePath(invsMap[inv.groupId]), invsMap[inv.groupId].name];
              }
              return invsPath[inv.id] || [];
            };
            return invs.map(inv => ({
              ...inv,
              path: [...definePath(inv), inv.name].join(' > '),
              field: 'inventory',
              value: createTermsFilter('products', inv.productIds)
            }));
          });
        });
      },
      filterModelsByProp = function (prop = '', facets = [], getter) {
        return getter().then(function (models) {
          const ids = facets.map(fp => fp.value);

          return models.filter(model => model[prop].some(id => !!~ids.indexOf(id)));
        });
      },
      filterFacets = function (facets, getter) {
        return getter().then(function (models) {
          return facets.reduce(function (sum, facet) {
            const found = models[facet.value];
            // ignore values for which there are no models
            return found ? sum.concat(angular.extend({}, facet, found)) : sum;
          }, []);
        });
      },
      filterModels = function (facets, getter) {
        return getter().then(function (models) {
          return Object.keys(models).map(function (id) {
            const model = models[id],
              found = find(facets, function (facet) {
                return facet.value === id;
              });
            return angular.extend({}, model, found || { value: model.id, amount: 0 });
          });
        });
      },
      flatTagsRecursive = function (tags, arr) {
        arr = arr || [];
        for (let index = 0; index < tags.length; index++) {
          const element = tags[index];
          flatTagsRecursive(element.tags, arr);
          element.tags = [];
          arr.push(element);
        }
        return arr;
      },
      filterModelsTags = function (facets, getter) {
        return getter().then(function (models) {
          const flatModel = flatTagsRecursive(
            Object.keys(models).map(function (id) {
              return angular.copy(models[id]);
            })
          );
          return flatModel.map(function (model) {
            const found = find(facets, function (facet) {
              return facet.value === model.id;
            });
            return angular.extend({}, model, found || { value: model.id, amount: 0 });
          });
        });
      },
      markChecked = function (field, facets, filters, additionalFields = []) {
        return facets.map(function (facet) {
          let facetFilter;
          const checked = has(filters, function (filter) {
            facetFilter = filter;

            return filter.field === field && filter.value === facet.value;
          });

          if (checked) {
            facet.checked = checked;
            additionalFields.forEach(additionalField => {
              facet[additionalField] = facetFilter[additionalField];
            });
          }

          return facet;
        });
      },
      markCheckedModels = function (models, filters, field = 'id') {
        return models.map(function (model) {
          model.checked = filters.some(filter => model[field] === filter[field]);

          return model;
        });
      },
      getMainParentTag = function (tag, flatTags, path = []) {
        let rootTag = tag.tagId ? angular.copy(flatTags.find(el => el.id === tag.tagId)) : tag;
        rootTag.pathToChild = path;
        if (rootTag.tagId && rootTag.tagId.length > 0) {
          rootTag = getMainParentTag(rootTag, flatTags, [rootTag.title, ...path]);
        }
        return rootTag;
      },
      postProcessDocs = function (docs) {
        function getTitleRecursive(tagId, flatTags) {
          let ret = [];
          for (let index = 0; index < flatTags.length; index++) {
            const element = flatTags[index];
            if (element.id === tagId) {
              if (element.tagId) {
                ret = getTitleRecursive(element.tagId, flatTags);
                ret.push(element.title);
              } else ret.push(element.title);
              break;
            }
          }

          return ret;
        }

        function groupTagsByMain(flatTags, ids, docCompanyIds) {
          let tags = {};
          const doesTagBelongsToDocCompanies = tag =>
            !!(
              tag.companyId === '' || // universal tag
              (tag.companyId && ~(docCompanyIds || []).indexOf(tag.companyId))
            );
          const doesTagBelongsToCurrentCompany = tag =>
            !!(
              tag.companyId === '' || // universal tag
              (vm.realCompanyId && tag.companyId && vm.realCompanyId === tag.companyId)
            );

          const tagsFiltered = flatTags.filter(function (tag) {
            if (ids.indexOf(tag.id) < 0) {
              return false;
            } else if (tag.administrative && !vm.isAdmin) {
              return false;
            } else if (!doesTagBelongsToCurrentCompany(tag)) {
              return false;
            } else if (!doesTagBelongsToDocCompanies(tag)) {
              return false;
            }
            return true;
          }, []);

          angular.copy(tagsFiltered).forEach(tag => {
            if (tag.tagId && tag.tagId.length > 0) {
              let rootTag = getMainParentTag(tag, flatTags);
              rootTag.groupTitle = rootTag.title;
              tag.fullPathTitle = [...rootTag.pathToChild, tag.title].join(' > ');
              rootTag.tags = [...get(tags, `${rootTag.id}.tags`, []), tag];
              rootTag.enabled = true;

              tags[rootTag.id] = rootTag;
            } else {
              tags[tag.id] = tag;
            }
          });
          return Object.values(tags);
        }

        function filterFlatTagGroup(flatTag) {
          const doesTagBelongsToCurrentCompany = tag =>
            !!(
              !tag.companyId ||
              (vm.realCompanyId && tag.companyId && vm.realCompanyId === tag.companyId)
            );

          return (
            (flatTag.administrative && !vm.isAdmin) || !doesTagBelongsToCurrentCompany(flatTag)
          );
        }

        function groupTags(flatTags, ids, docCompanyIds) {
          const doesTagBelongsToDocCompanies = tag =>
            !!(tag.companyId && ~(docCompanyIds || []).indexOf(tag.companyId));

          const tags = flatTags.reduce(function (acc, parentTag) {
            parentTag.tags = flatTags.filter(function (tag) {
              if (tag.tagId !== parentTag.id) {
                return false;
              } else if (ids.indexOf(tag.id) < 0) {
                return false;
              } else if (!doesTagBelongsToDocCompanies(tag)) {
                return false;
              }
              return true;
            }, []);

            if (parentTag.tags.length > 0) {
              const groupTagEnabled =
                $rootScope.companySettings.groupTagEnabled ||
                $rootScope.companySettings.groupTagsByParent;
              parentTag.groupTitle = parentTag.title;
              if (groupTagEnabled && parentTag.tags.length !== 1) {
                const parentTitle = getTitleRecursive(parentTag.id, flatTags);
                parentTag.groupTitle = parentTitle.join(' / ');
                acc.push(parentTag);
              } else acc = acc.concat(parentTag.tags);
            }

            if (
              !parentTag.isParent &&
              !parentTag.tagId &&
              ids.includes(parentTag.id) &&
              (doesTagBelongsToDocCompanies(parentTag) || !parentTag.companyId)
            )
              acc.push(parentTag);

            return acc;
          }, []);

          return uniq(tags);
        }
        const uniq = function (itms) {
            return (itms || []).reduce(function (sum, itm) {
              return ~sum.indexOf(itm) ? sum : sum.concat(itm);
            }, []);
          },
          expandTags = function (tagModels, ids, docCompanyIds) {
            const flatTags = Object.keys(tagModels).map(function (el) {
              return angular.copy(tagModels[el]);
            });
            const tags = $rootScope.companySettings.groupTagsByParent
              ? groupTagsByMain(flatTags, ids, docCompanyIds)
              : groupTags(flatTags, ids, docCompanyIds);

            return tags;
          },
          expandCodes = function (codes = {}, codeIds = []) {
            return Object.keys(codes).reduce((sum, key) => {
              const code = codes[key];

              return codeIds.includes(code.id) &&
                code.companySettingId === vm.realCompanyId &&
                code.isEnabled
                ? sum.concat(code)
                : sum;
            }, []);
          },
          expandAttrs = function (companyAttrs = [], attrs = {}) {
            const docCompanyAttrs = attrs[vm.realCompanyId] || {};

            return Object.keys(docCompanyAttrs).reduce(
              (sum, key) =>
                companyAttrs.includes(key) ? sum.concat(`${key}: ${docCompanyAttrs[key]}`) : sum,
              []
            );
          };
        return $q
          .all([
            getTags(),
            getManufacturers(),
            getDisposalCodes(),
            getStorageCodes(),
            getEnabledCustomFields(vm.realCompanyId)
          ])
          .then(function (data) {
            const tagModels = data[0],
              manuModels = data[1],
              disposalCodes = data[2],
              storageCodes = data[3],
              companyAttrs = data[4];

            const flatTags = Object.keys(tagModels)
              .map(function (el) {
                return angular.copy(tagModels[el]);
              })
              .filter(tag => !filterFlatTagGroup(tag));

            return docs.map(function (doc) {
              doc.tag = expandTags(
                flatTags,
                uniq(doc.tag),
                uniq((doc.companyId || []).concat(vm.realCompanyId))
              );
              doc.manufacturer = manuModels[doc.manufacturer];
              doc.disposalCodes = expandCodes(disposalCodes, doc.disposalCodes);
              doc.storageCodes = expandCodes(storageCodes, doc.storageCodes);
              doc.attrs = expandAttrs(companyAttrs, doc.attrs);
              return doc;
            });
          });
      },
      postProcessResp = function (resp) {
        return postProcessDocs(resp.docs).then(function (docs) {
          resp.docs = docs;
          return resp;
        });
      },
      composeTags = function (tags) {
        return (tags || [])
          .map(function (tag) {
            return tag.title;
          })
          .join(', ');
      },
      composeInventories = function (inventories) {
        let res = [];
        if (vm.inventoriesObject) {
          inventories.forEach(invId => {
            if (vm.inventoriesObject[invId]) {
              res.push(vm.inventoriesObject[invId].path);
            }
          });
        }

        return res.join(', ');
      },
      composeHazards = function (hazards) {
        return (hazards || []).map(function (hazard) {
          return HazardService.getTitle(hazard);
        });
      },
      toDayFilter = $filter('date'),
      toDay = function (ts) {
        return toDayFilter(ts, 'yyyy-MM-dd');
      },
      composeExportDoc = function (doc) {
        const data = {
          Name: doc.chemicalFriendlyName || doc.filename || doc.id,
          'Hazard Types': composeHazards(doc.hazardType),
          Tags: composeTags(doc.tag)
        };
        if ($rootScope.companySettings.inventoryInSearchEnabled) {
          data['Inventories'] = composeInventories(doc.inventories);
        }
        data['Manufacturer'] = (doc.manufacturer || {}).name;
        data['Latest Revision Dat'] = doc.issueDate ? toDay(doc.issueDate) : doc.issueDateValue;

        if (doc.disposalCodes && doc.disposalCodes.length)
          data['Disposal Codes'] = doc.disposalCodes[0].title;
        if (doc.storageCodes && doc.storageCodes.length)
          data['Storage Codes'] = doc.storageCodes[0].title;
        if (doc.attrs && doc.attrs.length) data['Custom Fields'] = doc.attrs.join('; ');

        return data;
      };

    vm.getSelectedCompany = function () {
      return vm.search.facets.companyId.reduce(function (sum, itm) {
        return sum || (itm.checked ? itm : sum);
      }, null);
    };

    vm.getSearchTarget = function () {
      const found = vm.getSelectedCompany();
      return found ? ' ' + found.name : '';
    };

    vm.selectCompany = SpinnerService.wrap(function (id) {
      let companyName = '';

      bustCache('inventoryWithProducts');

      vm.search.facets.companyId.forEach(function (itm) {
        itm.checked = itm.value == id;
        if (itm.checked) companyName = itm.name;
        vm.companyId = vm.realCompanyId = id;
      });

      if (id && vm.search.facets.ppe) {
        vm.search.facets.ppe.forEach(itm => {
          if (itm.checked) {
            itm.checked = false;
          }
        });
      }

      const companyPpeRegex = /^ppe_/;
      const companyPpeFacetFields = Object.keys(vm.search.facets)
        .filter(facet => companyPpeRegex.test(facet))
        .filter(facet => facet !== `ppe_${id}`);
      companyPpeFacetFields.forEach(key => {
        vm.search.facets[key].forEach(itm => {
          if (itm.checked) {
            itm.checked = false;
          }
        });
      });

      if (companyName) {
        return $q(function (resolve) {
          const res = $rootScope.onChangeMainCompany(companyName, false, function () {
            vm.initArchivedToggle();
            vm.initSensitiveTags();
            defaults.facetFields = getFacetFields(vm.isAdmin);
            vm.doSearch({ offset: 0 });
          });
          resolve(res);
        });
      } else return vm.doSearch({ offset: 0 });
    });

    vm.searchAsCompany = function (id, payload = {}) {
      let filters = collectCheckedFacets(vm.search.facets);

      filters = filters.concat(vm.inventoryWithProducts.filter(inv => inv.checked));
      filters = filters.map(filter => {
        if (filter.field && filter.field === 'companyId') {
          return { field: 'companyId', value: id };
        }

        return filter;
      });

      return vm.doSearch({ ...payload, filters }).then(() => {
        vm.throughBroaderSearch = false;
        vm.throughOtherCompany = true;
      });
    };

    vm.noFacets = function (facets) {
      return !Object.keys(facets).reduce(function (sum, name) {
        return sum || facets[name].length;
      }, false);
    };

    vm.buildInventoriesTree = function (inventories) {
      vm.inventoryWithProductsTree = createTree(inventories, 'groupId', 'inventories');
    };

    vm.assignSearch = function (searchData) {
      const search = (vm.search = angular.merge({}, defaults.search, searchData || {}));

      if (isInventoryEnabled() && search.facets.products.length) {
        filterModelsByProp('productIds', search.facets.products, () =>
          getInventoryWithProducts(vm.realCompanyId)
        ).then(inventoryWithProducts => {
          vm.inventoryWithProducts = markCheckedModels(
            inventoryWithProducts || [],
            vm.search.filters
          );
          vm.buildInventoriesTree(vm.inventoryWithProducts);
        });
      }

      return $q
        .all({
          search: search,
          hazardType: search.facets.hazardType,
          disposalCodes:
            search.facets.disposalCodes.length &&
            filterFacets(search.facets.disposalCodes, getDisposalCodes),
          storageCodes:
            search.facets.storageCodes.length &&
            filterFacets(search.facets.storageCodes, getStorageCodes),
          language: search.facets.language,
          manufacturer:
            search.facets.manufacturer.length &&
            filterFacets(search.facets.manufacturer, getManufacturers),
          tag: search.facets.tag.length && filterModelsTags(search.facets.tag, getTags),
          companyId:
            (search.facets.companyId.length || vm.isAdmin) &&
            filterModels(search.facets.companyId, getCompanies),
          ppe: search.facets.ppe,
          [`ppe_${$rootScope.companyId}`]: search.facets[`ppe_${$rootScope.companyId}`]
        })
        .then(function (data) {
          let languages = new Map();

          //fill languages from <lang>VersionLink
          $rootScope.languages.forEach(lang => {
            if (search.facets[lang + 'VersionLink']) {
              languages.set(lang, {
                value: lang,
                amount: search.facets[lang + 'VersionLink'],
                checked: false
              });
            }
          });

          //fill languages from document language
          data.language.forEach(el => {
            languages.set(el.value, el);
          });
          vm.search.facets = {
            hazardType: markChecked('hazardType', data.hazardType || [], vm.search.filters),
            disposalCodes: markChecked(
              'disposalCodes',
              data.disposalCodes || [],
              vm.search.filters
            ),
            storageCodes: markChecked('storageCodes', data.storageCodes || [], vm.search.filters),
            language: markChecked('language', [...languages.values()], vm.search.filters),
            manufacturer: markChecked('manufacturer', data.manufacturer || [], vm.search.filters),
            tag: markChecked('tag', data.tag || [], vm.search.filters, ['disabled']),
            companyId: markChecked('companyId', data.companyId || [], vm.search.filters),
            ppe: markChecked('ppe', data.ppe || [], vm.search.filters),
            [`ppe_${$rootScope.companyId}`]: markChecked(
              `ppe_${$rootScope.companyId}`,
              data[`ppe_${$rootScope.companyId}`] || [],
              vm.search.filters
            )
          };

          if (searchData) vm.advancedSearchReady = true;
        })
        .catch(simpleCatch);
    };

    vm.addArchivedFilter = function (payload) {
      const selectedCompany = payload.filters.find((el, i) => el.field === 'companyId');

      if (!vm.addArchived && selectedCompany) {
        const archivedTagsIds = vm.archivedTags.map(tag => tag.id);
        if (archivedTagsIds.length) {
          payload.filters.push(`-tag:(${archivedTagsIds.join(' ')})`);
        }
      }
      return payload;
    };

    vm.addSensitiveFilter = function (payload) {
      const selectedCompany = payload.filters.find((el, i) => el.field === 'companyId');

      if (!vm.addSensitive && selectedCompany) {
        const sensitiveTagsIds = vm.sensitiveTags.map(tag => tag.id);
        if (sensitiveTagsIds.length) {
          payload.filters.push(`-tag:(${sensitiveTagsIds.join(' ')})`);
        }
      }
      return payload;
    };

    vm.doSearch = SpinnerService.wrap(function (
      params = {},
      append = false,
      autoAssignSearch = true
    ) {
      vm.showHideArchivedToggle();
      vm.advancedSearch = false;
      let payload = {
        query: vm.search.query,
        facets: defaults.facetFields,
        filters: collectCheckedFacets(vm.search.facets),
        limit: vm.search.limit,
        offset: vm.search.offset,
        andOrStrategy: vm.search.andOrStrategy,
        fields: vm.isAdmin ? ['duplicateOfId', 'inventories'] : ['inventories'],
        addLegislations: false,
        siteLanguage: $rootScope.siteLanguage
      };

      payload = vm.addArchivedFilter(payload);
      payload = vm.addSensitiveFilter(payload);
      const selectedCompany = payload.filters.find((el, i) => el.field === 'companyId');

      payload.filters = payload.filters.concat(
        vm.inventoryWithProducts.filter(inv => inv.checked && !inv.disabled)
      );

      payload = {
        ...payload,
        ...params
      };

      if (!vm.addArchived && selectedCompany) {
        const archivedTagsIds = vm.archivedTags.map(tag => tag.id);
        if (archivedTagsIds.length) {
          payload.filters.push(`-tag:(${archivedTagsIds.join(' ')})`);
        }
      }

      if (!vm.addSensitive && selectedCompany) {
        const sensitiveTagsIds = vm.sensitiveTags.map(tag => tag.id);
        if (sensitiveTagsIds.length) {
          payload.filters.push(`-tag:(${sensitiveTagsIds.join(' ')})`);
        }
      }

      return SolrDocument.findSds(payload)
        .$promise.then(postProcessResp)
        .then(function (resp) {
          payload.filters = payload.filters.filter(function (el) {
            return !el.indexOf || el.indexOf('-tag:(') === -1;
          });
          vm.lastSearchResults = {
            query: payload.query,
            filters: payload.filters,
            facets: resp.meta.found ? resp.facets : vm.search.facets,
            docs: append ? vm.search.docs.concat(resp.docs) : resp.docs,
            meta: resp.meta,
            andOrStrategy: vm.search.andOrStrategy
          };

          return autoAssignSearch ? vm.assignSearch(vm.lastSearchResults) : vm.lastSearchResults;
        })
        .catch(simpleCatch);
    });

    vm.onSearch = function (params, append) {
      vm.throughBroaderSearch = false;
      vm.throughOtherCompany = false;
      return vm.doSearch(params, append);
    };

    vm.export = SpinnerService.wrap(async function () {
      let payload = {
        query: vm.search.query,
        facets: [],
        filters: collectCheckedFacets(vm.search.facets),
        limit: MAX_INT,
        offset: 0,
        andOrStrategy: vm.search.andOrStrategy,
        siteLanguage: $rootScope.siteLanguage
      };

      if ($rootScope.companySettings.inventoryInSearchEnabled) {
        payload.fields = ['inventories'];
      }

      payload.filters = payload.filters.concat(vm.inventoryWithProducts.filter(inv => inv.checked));

      payload = vm.addArchivedFilter(payload);
      payload = vm.addSensitiveFilter(payload);

      const company = vm.getSelectedCompany();
      if (company) {
        vm.inventoriesObject = await InventoryService.getTreeInfo(company.value);
      }

      return SolrDocument.findSds(payload)
        .$promise.then(postProcessResp)
        .then(async function (resp) {
          const setDocsHeaders = docs => {
            if (docs[0]) {
              let headers = [];
              docs.forEach(doc => {
                headers = [...new Set(headers.concat(Object.keys(doc)))];
              });
              headers.forEach(f => {
                docs[0][f] = docs[0][f] || '';
              });
            }
            return docs;
          };

          let data = setDocsHeaders(resp.docs.map(composeExportDoc));
          const { searchStr, filterStr } = await vm.getSearchDetails();
          const csv = ExportService.toCSV(data, {
            beforeHead: [[`${searchStr}\n${filterStr}`], []]
          });
          ExportService.download(csv, 'search.csv');
        })
        .catch(simpleCatch);
    });

    vm.doActionWithSearchResults = SpinnerService.wrap(async function (email, action, params = {}) {
      const { searchStr, filterStr } = await vm.getSearchDetails();

      let payload = {
        email: email,
        query: vm.search.query,
        facets: [],
        filters: collectCheckedFacets(vm.search.facets),
        limit: MAX_INT,
        offset: 0,
        andOrStrategy: vm.search.andOrStrategy,
        searchDetails: `${searchStr}\n${filterStr}`
      };

      const company = vm.getSelectedCompany();
      if ($rootScope.companySettings.inventoryInSearchEnabled && company) {
        payload.companyId = company.value;
        payload.fields = ['inventories'];
        payload.inventoryInSearchEnabled = true;
      }
      payload.filters = payload.filters.concat(vm.inventoryWithProducts.filter(inv => inv.checked));

      payload = vm.addArchivedFilter(payload);
      payload = vm.addSensitiveFilter(payload);

      payload = {
        ...payload,
        ...params
      };

      return getActionsWithSearchResults(action)(payload).catch(simpleCatch);
    });
    vm.getSearchDetails = async function () {
      const fieldsToAdd = [
        'tag',
        'manufacturer',
        'language',
        'storageCodes',
        'disposalCodes',
        'inventory'
      ];
      let details = { searchStr: '', filterStr: '' };

      if (vm.search.query !== '') {
        details.searchStr = `${await $translate('SEARCH.TITLE')}: ${vm.search.query}`;
      }

      let filter = vm.search.filters
        .filter(el => fieldsToAdd.includes(el.field) && vm.notCompanyId(el) && !el.disabled)
        .map(el => {
          return el.field === 'tag'
            ? getTagPath(vm.search.facets.tag, el.id).join('/')
            : el.title || el.name || el.value;
        });
      if (filter.length > 0) {
        details.filterStr = details.filterStr.concat(
          `${await $translate('COMMON.LABELS.FILTER_BY')}: ${filter.join(' & ')} `
        );
      }
      return details;
    };
    vm.prompt4action = function (action, payload = {}) {
      return ModalService.open(downloadNotification(action))
        .then(function (result) {
          payload.doubleSidedPrinting = result.doubleSidedPrinting;
          payload.addContentTableToPrinting = result.addContentTableToPrinting;
          payload.printOnlyUpdated = result.printOnlyUpdated;
          payload.printOnlyUpdatedLimit = result.printOnlyUpdatedLimit;

          vm.doActionWithSearchResults(result.email, action, payload);
        })
        .then(function () {
          MessagesService.info('COMMON.MESSAGES.WILL_BE_NOTIFIED');
        });
    };

    vm.inventoryPrompt4action = function (
      action,
      productIds,
      { tags = [], andOrStrategy = 'and' }
    ) {
      const payload = {
        filters: [
          { field: 'companyId', value: $rootScope.companyId },
          { field: 'inventory', value: createTermsFilter('products', productIds) }
        ],
        andOrStrategy
      };

      if (tags.length) {
        payload.filters = payload.filters.concat(
          tags.map(tag => ({ ...tag, field: 'tag', value: tag.id }))
        );
      }

      return vm.prompt4action(action, payload);
    };

    vm.onReset = function () {
      vm.isAdmin = $rootScope.checkIfUserIs(['admin', 'sdsEditor']);
      vm.companyId = getCorrectCompanyId();
      vm.realCompanyId = vm.isAdmin ? null : $rootScope.companyId;
      defaults.facetFields = getFacetFields(vm.isAdmin);
      defaults.search.filters = getSearchFilters(vm.isAdmin, vm.companyId);
      vm.advancedSearch = null;
      vm.assignSearch();
    };

    vm.removeFilter = function (target) {
      let targetValues = [target.value];

      if (target.field === 'tag' && target.isParent) {
        targetValues = getParentTagFilterValues(target);
      }

      return vm.doSearch({
        filters: vm.search.filters.filter(function (filter) {
          return !targetValues.includes(filter.value);
        })
      });
    };

    vm.notCompanyId = function (filter) {
      return filter.field !== 'companyId';
    };

    vm.onPageChange = function (params) {
      const page = vm.search.page,
        offset = getOffset(page, vm.search.limit);
      const payload = angular.extend({ offset: offset }, params || {});
      let searchPromise;

      if (vm.throughBroaderSearch) {
        searchPromise = vm.onBroaderSearch(payload);
      } else if (vm.throughOtherCompany) {
        searchPromise = vm.searchAsCompany($rootScope.currentCompany.sdsParentCompanyId, payload);
      } else {
        searchPromise = vm.doSearch(payload);
      }

      return searchPromise.then(function () {
        vm.search.offset = offset;
        vm.search.page = page;
      });
    };

    vm.isExhausted = function () {
      return (
        !vm.search.docs.length ||
        vm.search.page >= Math.ceil(vm.search.meta.found / vm.search.limit) ||
        vm.search.docs.length >= vm.search.meta.found
      );
    };

    vm.isPagerNecessary = function () {
      return vm.search.docs.length && vm.search.docs.length < vm.search.meta.found;
    };

    vm.toggleAdvancedSearch = function () {
      vm.advancedSearch = !vm.advancedSearch;

      if (!vm.advancedSearchReady && vm.lastSearchResults) {
        setTimeout(() => vm.assignSearch(vm.lastSearchResults), 500);
      }
    };

    vm.onBroaderSearch = function (payload = {}) {
      vm.throughBroaderSearch = true;
      vm.throughOtherCompany = false;
      return vm.doSearch({ ...payload, filters: [] });
    };

    vm.preFetch = SpinnerService.wrap(function (reset) {
      return CompanyService.getCurrentCompanyPromise().$promise.then(() => {
        reset && bustCache();
        const promises = [
          getStorageCodes(),
          getDisposalCodes(),
          getManufacturers(),
          getCompanies(),
          getTags()
        ];
        if (isInventoryEnabled()) promises.push(getInventoryWithProducts(vm.realCompanyId));

        return $q.all(promises);
      });
    });
    vm.isVerifiedByLang = function (doc) {
      var lang = $rootScope.siteLanguage;
      const docContainLanguage = doc.language.reduce(function (acc, cur) {
        return cur === lang ? true : acc;
      }, false);
      var res;
      if ((lang === 'en' && !docContainLanguage) || (lang === 'fr' && !docContainLanguage)) {
        res = doc.isVerified;
      } else if (lang === 'en') {
        res = doc.enIsVerified;
      } else if (lang === 'fr') {
        res = doc.frIsVerified;
      }
      return res;
    };

    vm.filterSdsBy = SpinnerService.wrap((filterBy, filterId, offset) => {
      const payload = {
        filterBy,
        filterId,
        offset,
        companyId: vm.companyId,
        limit: vm.search.limit,
        lang: $rootScope.siteLanguage,
        ...(!vm.addSensitive ? { withoutTags: vm.sensitiveTags.map(tag => tag.id) } : {})
      };

      return SolrDocument.filterSdsBy(payload)
        .$promise.then(postProcessResp)
        .then(function (resp) {
          return vm.assignSearch({
            docs: resp.docs,
            meta: resp.meta
          });
        })
        .catch(simpleCatch);
    });

    /**
     * Initiate data to show archived SDS toggle
     */
    vm.initArchivedToggle = async function () {
      //apply company settings filter
      vm.addArchived = $rootScope.companySettings.addArchivedToSearchSds;
      await loadArchivedTags();
      vm.showHideArchivedToggle();
    };

    /**
     * Initiate data to show sensitive SDS
     */
    vm.initSensitiveTags = async function () {
      vm.addSensitive = $rootScope.checkIfUserIs(['admin', 'teamAdmin'])
        ? true
        : $rootScope.currentUser && $rootScope.currentUser.allowToViewSensitiveSds;
      await loadSensitiveTags();
    };

    /**
     * Show or hide archived SDS toggle if company has such documents
     */
    vm.showHideArchivedToggle = async function () {
      let filters = get(vm.search, 'facets.companyId', false)
        ? collectCheckedFacets({ companyId: vm.search.facets.companyId })
        : [];

      const payload = {
        query: '',
        facets: ['tag'],
        filters,
        limit: 0,
        offset: 0,
        siteLanguage: $rootScope.siteLanguage
      };

      const tagsIds = vm.archivedTags.map(el => el.id);
      if (tagsIds.length) {
        payload.filters.push(`tag:(${tagsIds.join(' ')})`);
      }
      const data = await SolrDocument.findSds(payload).$promise;

      const tagFacetData = data.facets.tag || [];
      vm.showArchivedToggle = !!tagFacetData.find(el => tagsIds.includes(el.value) && el.amount);
    };

    vm.addArchivedToggle = function (value) {
      vm.addArchived = value === 'on';
    };

    function isInventoryEnabled() {
      return $rootScope.isModuleEnabled($rootScope.moduleNames.inv, true);
    }

    /**
     * Load archived tags data
     */
    async function loadArchivedTags() {
      if (!vm.archivedTags.length) {
        vm.archivedTags = await TagService.getArchivedTags(vm.realCompanyId);
      }
    }

    /**
     * Load sensitive tags data
     */
    async function loadSensitiveTags() {
      if (!vm.sensitiveTags.length) {
        vm.sensitiveTags = await TagService.getSensitiveTags(vm.realCompanyId);
      }
    }

    /**
     * Initialization
     */
    async function init() {
      await CompanyService.getCurrentCompanyPromise().$promise;

      defaults.facetFields = getFacetFields(vm.isAdmin);

      vm.initArchivedToggle();
      vm.initSensitiveTags();
      await vm.assignSearch();
    }

    init();
  }
]);

function getParentTagFilterValues(tag) {
  return [tag.value].concat(...(tag.tags || []).map(getParentTagFilterValues));
}
